import React, { useEffect } from 'react'
import Footer from '../component/Footer'
import { Link } from 'react-router-dom'
import { useGlobalContext } from '../context/context'

const Home = () => {
  const { setMainNavSelection } = useGlobalContext()
  useEffect(() => {
    setMainNavSelection('HOME')
    // eslint-disable-next-line
  }, [])
  const styles = {
    para: {
      marginTop: 20,
    },
    list: { marginLeft: 40 },
  }
  return (
    <div className='homescreen'>
      <h3 className='center'>DECENTRALIZED ORGANIZATION </h3>
      <div style={{ margin: 40 }}>
        <div style={{ marginTop: 40 }}>
          Dynamic markets require the creative handling of the organizations.
        </div>
        <div style={styles.para}>
          Creative handling requires intelligence, which is the essential condition of human
          nature. All employees have it. Our colleagues need to have an adequate system to unleash
          and make use of it.
        </div>
        <div style={styles.para}>
          In a complex and dynamic environment, all activities attempting to plan the future,
          control the budget, allocate the resource ahead of time, and steer from central doomed to
          fail. This way is wrong, in-human, a waste of energy, and false belief of management
          style. It is outdated!
        </div>
        <div style={styles.para}>
          A decentralized organization have the capability and proper robustness to deal with
          complexity. People in those organizations can freely act, create options, groove with the
          market, and beat the competition
        </div>
        <div style={styles.para}>
          Decentralized organizations are more agile, more human, and showing more outstanding
          performance.
        </div>
        <br></br>
        <div className='freeAssessmentContainer'>
          <Link className='header-ele2' to='/initiatefreesurvey'>
            <button className='freeAssessment'>Try Free Assessment? </button>
          </Link>

          <Link className='header-ele2 ' to='/signUp'>
            <button className='freeAssessment'>Looking for more assessments? </button>
          </Link>
        </div>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <div style={styles.para}>
          Here are the principles of decentralized organizations, which are the same as BetaCodex®*
        </div>

        <div style={styles.para}>
          <ol style={styles.list}>
            <li>
              <b>Team autonomy</b>: Connectedness with purpose, not dependency
            </li>
            <li>
              <b>Federalization</b>: Integration into cells, not division into silos
            </li>
            <li>
              <b>Leaderships</b>: Self-organization, not management
            </li>
            <li>
              <b>All-around success</b>: Comprehensive fitness, not mono-maximization
            </li>
            <li>
              <b>Transparency</b>: Flow intelligence, not power obstruction
            </li>
            <li>
              <b>Market orientation</b>: Relative Targets, not top-down prescription
            </li>
            <li>
              <b>Conditional income</b>: Participation, not incentives
            </li>
            <li>
              <b>Presence of mind</b>: Preparation, not planned economy
            </li>
            <li>
              <b>Rhythm</b>: Tact {'&'} groove, not fiscal-year orientation
            </li>
            <li>
              <b>Mastery-based decision</b>: Consequence, not bureaucracy
            </li>
            <li>
              <b>Resource discipline</b>: Expedience, not status-orientation
            </li>
            <li>
              <b>Flow coordination</b>: Value-creation dynamics, not static allocations
            </li>
          </ol>
          <br></br>
          <i style={{ fontSize: '70%' }}>
            *This work is derived from the BetaCodex©, an open source, free social technology
            published under the CC-BY-SA-4.0 license from Creative Commons, and found here:
            www.betacodex.org
          </i>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Home
