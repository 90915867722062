import React, { useState, useEffect } from 'react'
import { LoginAPI } from '../api/user'
import { useGlobalContext } from '../context/context'
import { Link } from 'react-router-dom'
import ShowAlert from '../utils/ShowAlert'

const LoginScreen = ({ location, history }) => {
  const { configureUser, setMainNavSelection } = useGlobalContext()
  const initpass =
    process.env.REACT_APP_LOCAL_DEV_ENV === 'ENABLED'
      ? process.env.REACT_APP_INIT_PASS_TEST_DONT_ADD
      : ''
  const inituser =
    process.env.REACT_APP_LOCAL_DEV_ENV === 'ENABLED'
      ? process.env.REACT_APP_INIT_LOGIN_TEST_DONT_ADD
      : ''

  const [email, setEmail] = useState(inituser)
  const [password, setPassword] = useState(initpass)
  const { setLoadingScreen } = useGlobalContext()

  useEffect(() => {
    setMainNavSelection('LOGIN')
    // eslint-disable-next-line
  }, [])
  const redirect = location.search ? location.search.split('=')[1] : '/'

  const handleSubmit = async (e) => {
    if (
      !password ||
      !email ||
      email === null ||
      email === '' ||
      password === null ||
      password === ''
    ) {
      ShowAlert('email and password needed')
      return
    }
    e.preventDefault()
    try {
      setLoadingScreen(true)
      const data = await LoginAPI({
        email: email.trim(),
        password: password.trim(),
      })
      configureUser(data)
      setLoadingScreen(false)
      history.push(redirect)
    } catch (error) {
      setLoadingScreen(false)

      error.response && error.response.data.message
        ? ShowAlert(error.response.data.message)
        : ShowAlert('Login Failed')
    }
  }

  return (
    <div className='login homescreen'>
      <div className='loginForm'>
        <div className='rowsb bold'>
          <div className='m10'> Email Id</div>
          <div>
            <input
              type='text'
              className='input-box'
              placeholder=' Email Id'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>
        <div className='rowsb bold'>
          <div className='m10'> Password</div>
          <div>
            <input
              type='password'
              className='input-box'
              placeholder=' password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
        </div>
        <div>
          <button onClick={handleSubmit} className='btn-success signinbtn'>
            Sign in
          </button>
        </div>

        <div className='rowsb'>
          <Link className='header-ele2' to='/changePassword'>
            <div className='blue m10'>Change Password?</div>
          </Link>
          <Link className='header-ele2' to='/signUp'>
            <div className='blue m10'>New User?</div>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default LoginScreen
