import axios from 'axios'

const token = localStorage.getItem('userToken')
  ? JSON.parse(localStorage.getItem('userToken'))
  : null

const config = {
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  },
}

const instance = axios.create({
  config,
})



export default instance
