import React from 'react'

const Footer = () => {
  return (
    <div className='myfooter'>
      <div className='footerBox'>
        <div className='myfooterTitle'> CONTACT DATA </div>
        <br></br>
        <div>
          <p>
            Ilker Demirel, Managing Director
            <br />
            Bergmannstr. 58, 10961 Berlin
          </p>
          <p>
            Tel: +49 173 611 02 01
            <br />
            info[at]decentralized-organization.com
          </p>

          {/* <p>
            <strong>DEZENTRALIZED ORGANIZATION</strong>
            <br></br>
            ausculto GmbH
            <br />
            Dipl.-Ing. Ilker Demirel
            <br />
            Bergmannstr. 58
            <br />
            10961 Berlin
          </p> */}
          {/* <p>
            Tel:
            <a href='tel:+491736110201'>+49 (0)173 611 02 01</a>
          </p>
          <p>
            <a
              href='mailto:
survey@decentralized-organization.com'
            >
              survey@decentralized-organization.com
            </a>
            <br />
            https://decentralized-organization.com
          </p> */}
        </div>
        <span className='seperator extralight-border'></span>
      </div>

      <div className='footerBox'>
        <div className='myfooterTitle'> LEGAL NOTICE </div>
        <div>
          <p>
            ausculto GmbH<br></br>
            Managing Director: Ilker Demirel
            <br />
            Registergericht: Amtsgericht Charlottenburg,
            <br />
            HRB 137107 B
            <br />
            Sitz der Gesellschaft: Berlin
          </p>
        </div>

        {/* <div className='myfooterTitle'>Follow us</div> */}
        {/* <div>
          <p>
            <a href='https://www.linkedin.com/in/ilkerdemirel/' rel='noopener'>
              LinkedIn
            </a>
          </p>
        </div>
        <span className='seperator extralight-border'></span> */}
      </div>

      <div className='footerBox right'>
        <div className='myfooterTitle'>DISCLAIMER </div>
        <div>
          The contents of our pages have been created with<br></br> the utmost care. However, we
          cannot guarantee <br></br>the contents accuracy, completeness or topicality.
          <br />
          {/* <a href='http://leadershipmindset.de/disclaimer/'>» Read more &#8230;</a> */}
        </div>
        <br></br>
        {/* <div className='myfooterTitle'>DATA PROTECTION </div>
        <div>
          <a href='http://leadershipmindset.de/data-protection/'>» Read more &#8230;</a>
        </div> */}
      </div>
    </div>
  )
}

export default Footer
