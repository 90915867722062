import React, { lazy, Suspense } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useLoading, Audio } from '@agney/react-loading'

import Loading from './component/Loader'
import Header from './component/Header'
import TakeTest from './Screens/TakeTest'
import Home from './Screens/Home'
import Login from './Screens/LoginScreen'

// import Admin from './Screens/Admin'
// import TestBank from './Screens/TestBank'
// import NewQuestion from './Screens/NewQuestion'
// import Settings from './Screens/Settings'
// import Surveys from './Screens/SureveyList'
// import SurveyDetails from './Screens/SurveyDetails'
// import AddSurvey from './Screens/AddSurvey'
// import SureveyReports from './Screens/SurevyReports'
// import Coupons from './Screens/Coupons'
// import AddCoupon from './Screens/AddCoupon'
// import AddUser from './Screens/AddUser'
// import Users from './Screens/UsersList'
// import Vendors from './Screens/VendorSureveyList'
// import AddVendorSurvey from './Screens/AddVendorSurvey'
// import SurveyInference from './Screens/SurveryInfererences'
// import ChangePassword from './Screens/ChangePassword'
// import VendorSurveyDetails from './Screens/VendorSurveyDetails'
// import FreeSurvey from './Screens/freeSurvey'

// const Login = lazy(() => import('./pages/auth/Login'))

const Admin = lazy(() => import('./Screens/Admin'))
const TestBank = lazy(() => import('./Screens/TestBank'))
const NewQuestion = lazy(() => import('./Screens/NewQuestion'))
const Settings = lazy(() => import('./Screens/Settings'))
const Surveys = lazy(() => import('./Screens/SureveyList'))
const SurveyDetails = lazy(() => import('./Screens/SurveyDetails'))
const AddSurvey = lazy(() => import('./Screens/AddSurvey'))
const SureveyReports = lazy(() => import('./Screens/SurevyReports'))
const Coupons = lazy(() => import('./Screens/Coupons'))
const AddCoupon = lazy(() => import('./Screens/AddCoupon'))
const AddUser = lazy(() => import('./Screens/AddUser'))
const Users = lazy(() => import('./Screens/UsersList'))
const Vendors = lazy(() => import('./Screens/VendorSureveyList'))
const AddVendorSurvey = lazy(() => import('./Screens/AddVendorSurvey'))
const SurveyInference = lazy(() => import('./Screens/SurveryInfererences'))
const ChangePassword = lazy(() => import('./Screens/ChangePassword'))
const VendorSurveyDetails = lazy(() => import('./Screens/VendorSurveyDetails'))
const FreeSurvey = lazy(() => import('./Screens/freeSurvey'))

function App() {
  const { containerProps, indicatorEl } = useLoading({
    loading: true,
    indicator: <Audio width='50' />,
  })

  return (
    <Suspense
      fallback={
        <div className='homescreen fullpage'>
          <div className='loadingSpinner'>
            <div className='loadingSpinnerContent'>
              <section {...containerProps}>{indicatorEl}</section>
            </div>
          </div>
        </div>
      }
    >
      <Router>
        <div className='sticky'>
          <Header />
        </div>
        <main>
          <ToastContainer
            className='toastClassName'
            bodyClassName={() => 'toastClassName'}
            // position='top-center'
            autoClose={6000}
          />

          <Loading />
          <Switch>
            <Route path='/surveyInference' component={SurveyInference} />
            <Route path='/addVendorSurvey' component={AddVendorSurvey} />
            <Route path='/vendor' component={Vendors} exact />
            <Route path='/vendor/:id' component={VendorSurveyDetails} />
            <Route path='/signUp' component={AddUser} />
            <Route path='/users' component={Users} />
            <Route path='/addCoupon' component={AddCoupon} />
            <Route path='/coupons' component={Coupons} />
            <Route path='/admin' component={Admin} />
            <Route path='/login' component={Login} />
            <Route path='/startSurvey/:vendorSurveyId' component={TakeTest} />
            <Route path='/addQuestion' component={NewQuestion} exact />
            <Route path='/updateQuestion/:id/:updatedby/:vendorSurveyId' component={NewQuestion} />
            <Route path='/testBank/:cat/:sub' component={TestBank} />
            <Route path='/settings' component={Settings} exact />
            <Route path='/surveys' component={Surveys} exact />
            <Route path='/surveys/:id' component={SurveyDetails} />
            <Route path='/addSurvey' component={AddSurvey} exact />
            <Route path='/reports/:surveyId/:vendorSurveyId/:route' component={SureveyReports} />
            <Route path='/changePassword' component={ChangePassword} exact />
            <Route path='/initiatefreesurvey' component={FreeSurvey} exact />
            <Route path='/freeSurvey/:vendorSurveyId' component={TakeTest} />

            <Route path='/' component={Home} exact />
            {/* <Route path='/vendor/orderlist' render={() => <OrderListScreen type='vendor' />} exact /> */}
          </Switch>
        </main>
      </Router>
    </Suspense>
  )
}

export default App
