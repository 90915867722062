import React, { useState, useContext } from 'react'

const AppContext = React.createContext()

const AppProvider = ({ children }) => {
  const [appSettings, setAppSettings] = useState([])
  const [loggedInUser, setLoggedInuser] = useState()
  const [infSettings, setInfSettings] = useState([])
  const [mainVavSelection, setMainNavSelection] = useState('HOME')
  const [subNavSelection, setSubNavSelection] = useState('SURVEYS')
  const [loadingScreen, setLoadingScreen] = useState(false)

  const configureUser = (usr) => {
    setLoggedInuser(usr)
  }
  const configureSettings = (settings) => {
    setAppSettings(settings)
  }

  const configureInfSettings = (settings) => {
    setInfSettings(settings)
  }

  return (
    <AppContext.Provider
      value={{
        appSettings,
        loggedInUser,
        configureUser,
        configureSettings,
        configureInfSettings,
        infSettings,
        mainVavSelection,
        setMainNavSelection,
        subNavSelection,
        setSubNavSelection,
        loadingScreen,
        setLoadingScreen,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export const useGlobalContext = () => {
  return useContext(AppContext)
}

export { AppContext, AppProvider }
