import axios from '../utils/Axios'
import ShowAlert from '../utils/ShowAlert'

const freeSurveyAPI = async (data) => {
  try {
    return await axios.post(`/api/user/freeSurveyRequest`, data)
  } catch (error) {
    error.response && error.response.data.message
      ? ShowAlert(error.response.data.message)
      : ShowAlert('Server Error')
  }
}

const getVendorSurveyByIdForUsersAPI = async (vendorSurveyId) => {
  try {
    return await axios.get(`/api/user/vendoSurveyById?_id=${vendorSurveyId}`)
  } catch (error) {
    error.response && error.response.data.message
      ? ShowAlert(error.response.data.message)
      : ShowAlert('Server Error')
  }
}

const submitSurveyAPI = async (response) => {
  try {
    return await await axios.post(`/api/user/surveyresponse`, response)
  } catch (error) {
    error.response && error.response.data.message
      ? ShowAlert(error.response.data.message)
      : ShowAlert('Server Error')
  }
}

const AddNewUserAPI = async (newUser) => {
  try {
    await axios.post(`/api/user/user`, newUser)
  } catch (error) {
    error.response && error.response.data.message
      ? ShowAlert(error.response.data.message)
      : ShowAlert('Server Error')
  }
}

const changePasswordAPI = async (newUser) => {
  try {
    return await axios.post(`/api/user/changepassword`, newUser)
  } catch (error) {
    error.response && error.response.data.message
      ? ShowAlert(error.response.data.message)
      : ShowAlert('Server Error')
  }
}

const LoginAPI = async (input) => {
  try {
    const out = await axios.post(`/api/user/login`, input)

    const { data } = out

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${data.token}`,
      },
    }

    axios.defaults.headers = config.headers
    return data
  } catch (error) {
    error.response && error.response.data.message
      ? ShowAlert(error.response.data.message)
      : ShowAlert('Server Error')
  }
}

export {
  freeSurveyAPI,
  getVendorSurveyByIdForUsersAPI,
  submitSurveyAPI,
  AddNewUserAPI,
  changePasswordAPI,
  LoginAPI,
}
