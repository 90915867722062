import React, { useState, useEffect } from 'react'
import { getVendorSurveyByIdForUsersAPI, submitSurveyAPI } from '../api/user'
import { useGlobalContext } from '../context/context'
import ShowAlert from '../utils/ShowAlert'

const TakeTest = ({ history, match }) => {
  const [init, setInit] = useState(true)
  const [language, setLanguage] = useState('en')
  const [survey, setSurvey] = useState()
  const [questionList, setQuestionList] = useState()
  const [responseSuite, setResponseSuite] = useState([])
  const [answer, setAnswer] = useState(999)
  const [position, setPosition] = useState()
  const [submitted, setSubmitted] = useState(false)
  const { setLoadingScreen } = useGlobalContext()

  const initiateTest = async () => {
    setPosition(0)
    setInit(false)
  }

  useEffect(() => {
    fetchTest()
    // eslint-disable-next-line
  }, [])

  const fetchTest = async () => {
    setLoadingScreen(true)
    const { data } = await getVendorSurveyByIdForUsersAPI(match.params.vendorSurveyId)
    setLoadingScreen(false)

    if (data === null) {
      ShowAlert('Survey Closed.')
      history.push('/')
      return
    }

    setSurvey(data)
    const newQuestionslist = []

    data.surveyResponseMap.forEach((element) => {
      element.alphalist.forEach((item) => {
        newQuestionslist.push(item.qn)
      })

      element.betalist.forEach((item) => {
        newQuestionslist.push(item.qn)
      })
    })

    const temp = newQuestionslist.map((el) => {
      return {
        question: el._id,
        response: null,
      }
    })

    setQuestionList(newQuestionslist)
    setResponseSuite(shuffleList(temp))
  }

  const shuffleList = (olist) => {
    const nlist = []
    const list = [...olist]
    for (let i = 0; i < list.length; i++) {
      const tc = Math.floor(Math.random() * list.nlist)
      nlist.splice(tc, 0, list[i])
    }
    return nlist
  }

  const getBasicInfo = () => {
    if (!questionList) return <></>
    return (
      <div className='container1'>
        <div>
          <div className='rowfs m10 bold'>
            <div>Language</div>
            <div className='m10'>
              <select
                className='vendor_select'
                name='language'
                value={language}
                onChange={(e) => setLanguage(e.target.value)}
              >
                <option value='en'>ENGLISH</option>
                <option value='dt'>DEUTSCH</option>
                {/* <option value='tr'>TÜRKÇE</option> */}
              </select>
            </div>
          </div>
          <div className='center' style={{ width: '95vw' }}>
            <div className='survey'>{`Welcome to ${
              survey.title && survey.title[language]
            } Survey`}</div>
            <div className='detailReportDescription'>
              {survey.description && survey.description[language]}
            </div>
          </div>

          <div className='full-btn-div'>
            <button onClick={initiateTest} className='m10 title'>
              START SURVEY
            </button>
          </div>

          {process.env.REACT_APP_LOCAL_DEV_ENV === 'ENABLED' && (
            <div className='full-btn-div'>
              <button onClick={simulate} className='m10 title'>
                Simulate
              </button>
            </div>
          )}
        </div>
      </div>
    )
  }

  const answerOptions = [
    {
      key: {
        en: 'Strongly Disagree',
        dt: 'Stimme überhaupt nicht zu',
        tr: 'TR Strongly Disagree',
      },
      value: 0,
    },
    {
      key: { en: 'Disagree', dt: 'Stimme nicht zu', tr: 'TR Disagree' },
      value: 25,
    },
    {
      key: {
        en: 'Neither Disagree nor Agree',
        dt: 'Stimme weder zu noch dagegen',
        tr: 'TR Neither Disagree nor Agree',
      },
      value: 50,
    },
    {
      key: {
        en: 'Agree',
        dt: 'Stimme zu',
        tr: 'TR Agree',
      },
      value: 75,
    },
    {
      key: {
        en: 'Strongly Agree',
        dt: 'Stimme vollständig zu',
        tr: 'TR Strongly Agree',
      },
      value: 100,
    },
  ]

  const viewTest = () => {
    return (
      <div key={position}>
        <div className='m10 title blue bold'>
          {position < responseSuite.length ? (
            <div>
              {questionView()}
              <div className='rowfs'>
                {position > 0 && (
                  <button className='m10 title  bold' onClick={prevQuestion}>
                    BACK
                  </button>
                )}
                <button className='m10 title  bold' onClick={nextQuestion}>
                  NEXT
                </button>
              </div>
              {`${responseSuite.length - position - 1} more Questions to Go`}
            </div>
          ) : (
            <>
              {!submitted ? (
                <button
                  disabled={submitted}
                  className='m10 title  bold center'
                  style={submitted ? { backgroundColor: 'gray' } : {}}
                  onClick={finishSurvey}
                >
                  Submit Survey
                </button>
              ) : (
                <div className='red bold'>Please Wait while we process the request...</div>
              )}
            </>
          )}
        </div>
      </div>
    )
  }

  const nextQuestion = () => {
    if (answer === 999) {
      ShowAlert('make a selection ')
      return
    }
    const temp = [...responseSuite]
    temp[position].response = answer

    setResponseSuite(temp)

    setAnswer(999)
    setPosition(position + 1)
  }

  const prevQuestion = () => {
    setAnswer(999)
    setPosition(position - 1)
  }

  const handleAnswerSelection = (ans) => {
    setAnswer(ans * 1)
  }

  const questionView = () => {
    const q = questionList.find((e) => e._id === responseSuite[position].question)
    return (
      <div className='m10'>
        <div className='nocopy' id='test'>
          {q && q.question[language]}
        </div>
        <div className='m10' key={`${q.question.en}`}>
          {answerOptions.map((ans, index) => (
            <div key={index}>
              <label className='shipping-address-label'>
                <input
                  type='radio'
                  value={ans.value}
                  className='m10'
                  checked={answer === ans.value}
                  onChange={(e) => handleAnswerSelection(e.target.value)}
                />
                {ans.key[language]}
              </label>
            </div>
          ))}
        </div>
      </div>
    )
  }

  const prepareRespPacket = async (suite) => {
    const response = {
      responses: suite,
      participantEmail: 'NA',
      surveyId: survey.surveyId,
      vendorSurveyId: match.params.vendorSurveyId,
      language,
    }

    try {
      setLoadingScreen(true)
      await submitSurveyAPI(response)
      setLoadingScreen(false)

      ShowAlert(
        'Thank you for your Participation. Result will be sent to the email Address once survey result is processed.',
        'success'
      )
      history.push('/')
    } catch (error) {
      console.log(error.response)
      error.response && error.response.data.message
        ? ShowAlert(error.response.data.message)
        : ShowAlert('Result submission Failed. Some error')
    }
  }

  const finishSurvey = async () => {
    setSubmitted(true)
    await prepareRespPacket(responseSuite)
  }

  const simulate = async () => {
    const table = [
      { low: 0, high: 24, value: 0 },
      { low: 25, high: 49, value: 25 },
      { low: 50, high: 74, value: 50 },
      { low: 75, high: 99, value: 75 },
      { low: 100, high: 999, value: 100 },
    ]
    const respList = responseSuite.map((el) => {
      const ans = Math.floor(Math.random() * 100)
      const sel = table.find((e) => e.low <= ans && e.high >= ans)
      return {
        ...el,
        response: sel.value,
      }
    })

    await prepareRespPacket(respList)
  }

  return <div>{init ? getBasicInfo() : viewTest()}</div>
}

export default TakeTest
