import React from 'react'
import { useGlobalContext } from '../context/context'
import { useLoading, Audio } from '@agney/react-loading'

const Loader = () => {
  const { loadingScreen } = useGlobalContext()
  const { containerProps, indicatorEl } = useLoading({
    loading: loadingScreen,
    indicator: <Audio width='50' />,
  })
  return (
    <>
      {loadingScreen && (
        <div className='loadingSpinner'>
          <div className='loadingSpinnerContent'>
            <section {...containerProps}>{indicatorEl}</section>
          </div>
        </div>
      )}
    </>
  )

  // return <section {...containerProps}>{indicatorEl}</section>
}

export default Loader
