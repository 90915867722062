import React from 'react'
import { FaUser } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { useGlobalContext } from '../context/context'

const Header = () => {
  const { mainVavSelection, loggedInUser } = useGlobalContext()

  const styles = {
    onSel: { border: '5px solid yellow', borderRadius: 10, padding: 5, color: 'yellow' },
    onUnSel: { padding: 1 },
  }

  const getCSS = (sel) => {
    const out = mainVavSelection === sel ? styles.onSel : styles.onUnSel
    return out
    // return 'header-ele'
  }

  return (
    <div id='header' style={{ width: '100vw' }}>
      <div className='navbar-header'>
        <Link className='header-ele' style={getCSS('HOME')} to='/'>
          HOME
        </Link>
        {loggedInUser && loggedInUser.isAdmin && (
          <>
            <Link className='header-ele' style={getCSS('ADMIN')} to='/surveys'>
              ADMIN
            </Link>
          </>
        )}
        {loggedInUser && (
          <>
            <Link className='header-ele' style={getCSS('VENDOR')} to='/vendor'>
              VENDOR
            </Link>
          </>
        )}
        <Link className='header-ele' style={getCSS('LOGIN')} to='/login'>
          <FaUser /> LOGIN
        </Link>
      </div>
    </div>
  )
}

export default Header
